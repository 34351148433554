/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef} from 'react';
import axios from 'axios';
import useDebounce from '../../utils/useDebounce';
import useLayerStore from '../../utils/useLayerStore';
import './Geocoder.css';

function Geocoder({map, use, currentPosition}) {
    const [place, setPlace] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [chosenPlace, setChosenPlace] = useState('');
    const geocoderRef = useRef();
    const debouncedPlace = useDebounce(place, 500);

    const goToResult = (result) => {
        map.current.flyTo({
            center:[result.geometry.coordinates[0], result.geometry.coordinates[1]],
            essential:true,
            zoom:18
        })
        setSearchResults([]);
        setChosenPlace(`${result.properties.housenumber||''} ${result.properties.street||''} ${result.properties.city||''} ${result.properties.state||''} ${result.properties.country||''} ${result.properties.postcode||''}`)
        geocoderRef.current.value = '';
        useLayerStore.setState({newOfficeCoords:[result.geometry.coordinates[0], result.geometry.coordinates[1]]})
    }

    useEffect(() => {
        if (currentPosition) {
            setChosenPlace(currentPosition)
        }
    }, [currentPosition])

    useEffect(() => {
        if (debouncedPlace.length>=3) {
            axios.get(`https://photon.komoot.io/api/?q=${debouncedPlace}&lat=45.5088&lon=-73.5617&limit=5&lang=fr`)
                .then((res) => {
                    setSearchResults([...res.data.features])
                })
                .catch(() => {
                })
        }
        if (debouncedPlace.length<3) {
            setSearchResults([])
        }
    }, [debouncedPlace])

    return (
            <div className={`${use==='map'?'geocoder-box-for-map':''} ${use==='profile'?'geocoder-box-for-profile':''}`} >
                <input placeholder={chosenPlace||'Chercher un lieu'} onChange={(e) => setPlace(e.target.value)} ref={geocoderRef} className='geocoder-input'/>
                {searchResults.length>0?
                    <div className='suggestions-box'>
                        {searchResults?.map((result, index) => (
                                <div className='suggestion-box' key={index}>
                                    <button onClick={() => goToResult(result)} type='button' className='result-btn'>
                                        {result.properties.name?`${result.properties.name}, `:null}
                                        {result.properties.housenumber?`${result.properties.housenumber}, `:null}
                                        {result.properties.street?`${result.properties.street}, `:null}
                                        {result.properties.city?`${result.properties.city}, `:null}
                                        {result.properties.country?result.properties.country:null} 
                                        </button>
                                </div>
                            )
                        )
                        }
                    </div>
                    :
                    null    
                }
            </div>
    )
}

export default Geocoder;