/* eslint-disable react/no-danger */
/* eslint-disable no-new */
/* eslint-disable no-alert */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef, useCallback} from 'react';
import maplibregl from 'maplibre-gl';
import { isMapboxURL, transformMapboxUrl } from 'maplibregl-mapbox-request-transformer';
import * as turf from '@turf/turf';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { MaplibreExportControl, Format, DPI} from "@watergis/maplibre-gl-export";
import '@watergis/maplibre-gl-export/css/styles.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import useLayerStore from '../utils/useLayerStore';
import Geocoder from './utils_cartes/Geocoder';
import CarteLeftSidebar from './utils_cartes/CarteLeftSidebar';
import CarteInfobar from './utils_cartes/CarteInfobar';
import './Carte.css';
// import waterdrop from './images/waterdrop.svg';
// import leaf from './images/leaf.svg';
// import soil from './images/soil.svg';
import menuIcon from '../images/menu-icon.svg';
import pencilIcon from '../images/pencil-icon.svg';
import rulerIcon from '../images/ruler-icon.svg';
import printIcon from '../images/print-icon.svg';
import customLayerLoader from './utils_cartes/customLayerLoader';
import getLayers from '../firebase';

function Main() {
    const [displaySidebar, setDisplaySidebar] = useState(false);
    const [displayInfobar, setDisplayInfobar] = useState(false);
    const [infobarData, setInfobarData] = useState([]);
    const [infobarLayerAttributes, setInfobarLayerAttributes] = useState([]);
    const [mapDisplayed, setMapDisplayed] = useState('mapbox://styles/mapbox/light-v11')
    const [isMeasuringDistances, setIsMeasuringDistances] = useState(false);
    const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);
    const [isPrintingMap, setIsPrintingMap] = useState(false);
    const [activeSidebar, setActiveSidebar] = useState('layers');
    const [geojsonForMeasurements, setGeojsonForMeasurements] = useState({
        'type': 'FeatureCollection',
        'features':[]
    });
    const mapContainer = useRef();
    const map = useRef();
    const layers = useLayerStore((state => state.layers))
    const checkedLayers = useLayerStore((state) => state.checkedLayers);
    const myLayers = useLayerStore((state) => state.myLayers);
    const mapboxKey = 'pk.eyJ1IjoiZWRvdWFyZGJsYWlzIiwiYSI6ImNsZTc4NTFhMTAydjMzcW94OXBvcXRlOTMifQ.52MrCusXJsjhSsRxm5oZYA';

    const transformRequest = (url, resourceType) => {
        if (isMapboxURL(url)) {
            return transformMapboxUrl(url, resourceType, mapboxKey)
        }
        return {url}
    }

    const linestringForMeasurements = {
        'type': 'Feature',
        'geometry': {
            'type': 'LineString',
            'coordinates': []
        }
    };

    const measureDistancesOnClick = useCallback((e) => {
        const distanceContainer = document.getElementById('distance');
        const features = map.current.queryRenderedFeatures(e.point, {
            layers: ['measure-points']
        });
        if (geojsonForMeasurements.features.length > 1) geojsonForMeasurements.features.pop();
        distanceContainer.innerHTML = '';
        if (features.length) {
            const {id} = features[0].properties;
            geojsonForMeasurements.features = geojsonForMeasurements.features.filter((point) => {
            return point.properties.id !== id;
            });
        } else {
            const point = {
            'type': 'Feature',
            'geometry': {
            'type': 'Point',
            'coordinates': [e.lngLat.lng, e.lngLat.lat]
            },
            'properties': {
            'id': String(new Date().getTime())
            }
            };
            geojsonForMeasurements.features.push(point);
        }         
        if (geojsonForMeasurements.features.length > 1) {
            linestringForMeasurements.geometry.coordinates = geojsonForMeasurements.features.map(
                (point) => {
                    return point.geometry.coordinates;
                }
            );
            geojsonForMeasurements.features.push(linestringForMeasurements);  
            const value = document.createElement('pre');
            value.setAttribute('id', 'distance');
            const distanceInKM = turf.length(linestringForMeasurements);
            const distanceinM = (distanceInKM * 1000).toFixed(2);
            value.innerHTML = `<p>Distance totale: <strong>${distanceinM.toLocaleString()}</strong> mètres</p>`;
            distanceContainer.appendChild(value);
        } 
        map.current.getSource('geojson').setData(geojsonForMeasurements);
    }, [geojsonForMeasurements]);

    const measureDistancesOnMouseMove = useCallback((e) => {
        if (map.current.getLayer('measure-points')) {
            const features = map.current.queryRenderedFeatures(e.point, {
                layers: ['measure-points']
            });
            map.current.getCanvas().style.cursor = features.length
            ? 'pointer'
            : 'crosshair';
        }
    }, []);

    const showOrHideSidebar = () => {
        if (displaySidebar) {
            setDisplaySidebar(false)
        } else if (!displaySidebar) {
            setDisplaySidebar(true)
        }
    }

    const showOrHideInfobar = () => {
        if (displayInfobar) {
            setDisplayInfobar(false)
        } else if (!displayInfobar) {
            setDisplayInfobar(true)
        }
    }

    const displayMap = (mapToDisplay) => {
        setMapDisplayed(mapToDisplay)
    }

    const measureDistances = () => {
        if (isMeasuringDistances) {
            setIsMeasuringDistances(false)
        } else {
            setIsMeasuringDistances(true)
            setIsDrawingPolygon(false)
            setIsPrintingMap(false)
        }
    }

    const drawPolygon = () => {
        if (isDrawingPolygon) {
            setIsDrawingPolygon(false)
        } else {
            setIsDrawingPolygon(true)
            setIsPrintingMap(false)
            setIsMeasuringDistances(false)
        }
    }

    const printMap = () => {
        if (isPrintingMap) {
            setIsPrintingMap(false)
        } else {
            setIsPrintingMap(true)
            setIsMeasuringDistances(false)
            setIsDrawingPolygon(false)
        }
    }

    const chooseSidebar = (choice) => {
        if (choice==='layers') {
            setActiveSidebar('layers')
        } else if (choice==='mylayers') {
            setActiveSidebar('mylayers')
        }
    }

    useEffect(() => {
        getLayers()
    }, [])

    useEffect(() => {
        setIsMeasuringDistances(false)
        setIsDrawingPolygon(false)
        let center = [-72.545088, 46.341778];
        let zoom = 12;
        if (map.current) {
            center = map.current.getCenter();
            zoom = map.current.getZoom();
            map.current.remove();
        }
        map.current = new maplibregl.Map({
          container: mapContainer.current,
          style:mapDisplayed,
          center,
          zoom,
          attributionControl:false,
          trackResize:true,
          transformRequest
          });
        map.current.on('load', () => { 
            map.current.addControl(new maplibregl.AttributionControl({
                customAttribution: 'Une réalisation de Koda',
                compact: true,
                position:'bottom-right'
            }), 'bottom-right');
            const draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true
                }
            })
            const updateArea = () => {
                const data = draw.getAll();
                const answer = document.getElementById('polygon');
                if (data.features.length > 0) {
                    const area = turf.area(data).toFixed(2);
                    answer.innerHTML = `<p>Aire totale: <strong>${ area}</strong> mètres carrés</p>`;
                } else if (data.features.length === 0) {
                    answer.innerHTML = `<p>Aire totale: <strong>0</strong> mètres carrés</p>`;
                }
            }
            map.current.addControl(draw);
            map.current.on('draw.create', updateArea);
            map.current.on('draw.delete', updateArea);
            map.current.on('draw.update', updateArea);

            const drawingBox = document.getElementsByClassName('maplibregl-ctrl-top-right')[0];
            drawingBox.style.visibility = 'hidden';
            map.current.addControl(new MaplibreExportControl({
                Format: Format.PNG,
                DPI: DPI[96],
                Local: 'fr',
            }), 'top-left');
            const printingBox = document.getElementsByClassName('maplibregl-ctrl-top-left')[0];
            printingBox.style.visibility = 'hidden';
        })
    }, [mapDisplayed, myLayers])

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                map.current.flyTo({
                  center: [longitude, latitude],
                  essential: true,
                  zoom: 11,
                });
              }
            );
          }
    }, [map])

    useEffect(() => {
        if (layers.length>0 && map) {
            map.current.on('load', () => {
                layers.forEach((layer) => {
                    const isMyLayer = false;
                    customLayerLoader(map.current, layer, isMyLayer)
                    map.current.setLayoutProperty(layer.id, 'visibility', 'none');
                });
                myLayers.forEach((myLayer) => {
                    const isMyLayer = true;
                    customLayerLoader(map.current, myLayer, isMyLayer)
                    map.current.setLayoutProperty(myLayer.file.name,'visibility','none');
                })
            })
        }
        if (checkedLayers.length>0 && map) {
            map.current.on('load', () => {
                checkedLayers.forEach((checkedLayer) => {
                    map.current.setLayoutProperty(checkedLayer.id?checkedLayer.id:checkedLayer.file.name,'visibility','visible');
                })
            })
        }
    }, [layers, myLayers, mapDisplayed])

    useEffect(() => {
        if (map.current && checkedLayers.length>0 && layers.length>0 && map) {
            checkedLayers.forEach((checkedLayer) => {
                map.current.setLayoutProperty(checkedLayer.id?checkedLayer.id:checkedLayer.file.name,'visibility','visible');
            })
            layers.forEach((layer) => {
                if (checkedLayers.filter((lay)=> lay?.id===layer.id).length===0) {
                    map.current.setLayoutProperty(layer.id,'visibility','none');
                }
            })
            myLayers.forEach((myLayer) => {
                    if (checkedLayers.filter((lay)=> lay?.file?.name===myLayer.file.name).length===0) {
                        map.current.setLayoutProperty(myLayer.file.name,'visibility','none');
                    }
            })
            map.current.on('click', (e) => {
                const layersIDandAttributes = []
                const layersID = []
                checkedLayers.forEach(layer => {
                    if (layer.id) {
                        layersIDandAttributes.push({id:layer.id, attributes:layer.attributes})
                        layersID.push(layer.id)
                    }
                    // ajouter logique myLayers
                })
                const bbox = [
                    [e.point.x - 5, e.point.y - 5],
                    [e.point.x + 5, e.point.y + 5]
                ];
                const selectedFeatures = map.current.queryRenderedFeatures(bbox, {layers: layersID});
                if (selectedFeatures.length>0) {
                    layersIDandAttributes.forEach(obj => {
                        if (obj.id === selectedFeatures[0].layer.id) {
                            setInfobarLayerAttributes(obj.attributes)
                        }
                    })
                    setInfobarData(selectedFeatures[0].properties)
                    setDisplayInfobar(true)
                }
            })
        } else if (map.current && checkedLayers.length===0 && layers.length>0 && map) {
           layers.forEach((layer) => {
                    if (map.current.getLayer(layer.id)) {
                        const visibility = map.current.getLayoutProperty(layer.id,'visibility');
                        if (visibility === 'visible') {
                            map.current.setLayoutProperty(layer.id,'visibility','none');
                        }
                    }
            })
            myLayers.forEach((myLayer) => {
                if (map.current.getLayer(myLayer.file.name)) {
                    const visibility = map.current.getLayoutProperty(myLayer.file.name,'visibility');
                    if (visibility === 'visible') {
                        map.current.setLayoutProperty(myLayer.file.name,'visibility','none');
                    }
                }
            })
        }
    }, [checkedLayers, map])

    useEffect(() => {
        const distanceContainer = document.getElementById('distance');
        distanceContainer.innerHTML='';
        if (isMeasuringDistances) {
            map.current.addSource('geojson', {'type':'geojson', 'data':geojsonForMeasurements});
            map.current.addLayer({
                id: 'measure-points',
                type: 'circle',
                source: 'geojson',
                paint: {
                    'circle-radius': 5,
                    'circle-color': '#000'
                },
                filter: ['in', '$type', 'Point']
            });
            map.current.addLayer({
                id: 'measure-lines',
                type: 'line',
                source: 'geojson',
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#000',
                    'line-width': 2.5
                },
                filter: ['in', '$type', 'LineString']
            })
            map.current.on('click',measureDistancesOnClick);
            map.current.on('mousemove', measureDistancesOnMouseMove);
        } else {
            map.current.getCanvas().style.cursor = 'pointer';
            map.current.off('click',measureDistancesOnClick);
            map.current.off('mousemove', measureDistancesOnMouseMove);
            const newGeojson = {
                'type': 'FeatureCollection',
                'features':[]
            }
            setGeojsonForMeasurements(newGeojson);
            if (map.current.getLayer('measure-points')) {
                map.current.removeLayer('measure-points')
            }
            if (map.current.getLayer('measure-lines')) {
                map.current.removeLayer('measure-lines')
            }
            if (map.current.getSource('geojson')) {
                map.current.removeSource('geojson')
            }
        }
    }, [isMeasuringDistances])

    useEffect(() => {
        if (map.current) {
            const drawingBox = document.getElementsByClassName('maplibregl-ctrl-top-right')[0];
            if (isDrawingPolygon) {
                drawingBox.style.visibility = 'visible';
            } else {
                drawingBox.style.visibility = 'hidden';
            }
        }
    }, [isDrawingPolygon])

    useEffect(() => {
        if (map.current) {
            const printingBox = document.getElementsByClassName('maplibregl-ctrl-top-left')[0];
            if (isPrintingMap) {
                printingBox.style.visibility='visible'
            } else {
                printingBox.style.visibility='hidden'
            }
        }
    }, [isPrintingMap, myLayers])

    return (
        <div className='main-box'>
            <Geocoder map={map} use='map'/>
            {displaySidebar && 
            <CarteLeftSidebar 
                showOrHideSidebar={showOrHideSidebar} 
                displayMap={displayMap} 
                chooseSidebar={chooseSidebar} 
                activeSidebar={activeSidebar} 
            />}
            <div className='map-wrap'>
                <div ref={mapContainer} className='map'/>
                <button className='menu-btn' type='button' onClick={showOrHideSidebar} style={{left:displaySidebar?'325px':'25px'}}>
                    <img src={menuIcon} alt='display or hide sidebar' className='menu-img'/>
                </button>
                <button className='pencil-btn' type='button' onClick={drawPolygon} style={{left:displaySidebar?'330px':'30px'}}>
                    <img src={pencilIcon} alt='dessiner un polygone' className='map-small-img'/>
                </button>
                <button className='ruler-btn' type='button' onClick={measureDistances} style={{left:displaySidebar?'330px':'30px'}}>
                    <img src={rulerIcon} alt='mesurer une distance' className='map-small-img'/>
                </button>
                <button className='print-btn' type='button' onClick={printMap} style={{left:displaySidebar?'330px':'30px'}}>
                    <img src={printIcon} alt='imprimer la carte' className='map-small-img white-filter'/>
                </button>
                <div id="distance" className="distance-container" style={{visibility:isMeasuringDistances?'visible':'hidden'}}/>
                <div id="polygon" className="polygon-container" style={{visibility:isDrawingPolygon?'visible':'hidden'}}/>
                <div className='print-surface' style={{visibility:isPrintingMap?'visible':'hidden'}}/>
            </div>
            {displayInfobar&& <CarteInfobar displayInfobar={displayInfobar}  showOrHideInfobar={showOrHideInfobar} infobarData={infobarData} layerAttributes={infobarLayerAttributes}/>}
        </div>
    )
}

export default Main;