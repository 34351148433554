function handleDragDropFile(file, stateUpdate){
    const extension = file.name.split(".").pop();
  
    if (extension === "json" || extension === "geojson"){
      const reader = new FileReader();
      
         reader.onload =  (event) => {
          const jsonObj = JSON.parse(event.target.result);
       
          stateUpdate(jsonObj)
         } 
          reader.readAsText(file);
    }
}


export default handleDragDropFile;