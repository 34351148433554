import React from 'react';
import landwiz from '../images/landwiz-logo.png';
import './Header.css';

function Header() {
    return (
        <div className='header-box'>
            <img alt='landwiz logo' src={landwiz} className='header-logo'/>
            <h2 className='beta-text'>Bêta</h2>
        </div>
    )
}

export default Header;