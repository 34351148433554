/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Layer from './Layer';
import DragDrop from './DragDrop';
import PDF from './PDF';
import useLayerStore from '../../utils/useLayerStore';
import plusIcon from '../../images/circle-plus-icon.svg';
import minusIcon from '../../images/circle-minus-icon.svg';
import map1 from '../../images/map1.png';
import map2 from '../../images/map2.png';
import map3 from '../../images/map3.png';
import map4 from '../../images/map4.png';
import kodaLogo from '../../images/koda-logo.png';
import backgroundLogo from '../../images/background-logo.png';
import layersIcon from '../../images/layers-icon.svg';
import mylayersIcon from '../../images/mylayers-icon.svg';
import closeIcon from '../../images/close-icon.svg';

function CarteLeftSidebar({showOrHideSidebar, displayMap, chooseSidebar, activeSidebar}) {
    const [displayCategory, setDisplayCategory] = useState([]);
    const myLayers = useLayerStore((state) => state.myLayers);
    const layers = useLayerStore((state) => state.layers);
    const layersCategories = useLayerStore((state) => state.layersCategories);

    const showLayers = (category) => {
        if (displayCategory.includes(category)) {
            const newCatArray = displayCategory.filter((cat) => category!==cat)
            setDisplayCategory(newCatArray)
        } else if (!displayCategory.includes(category)) {
            setDisplayCategory((prevState) => [...prevState, category])
        }
    }

    return (
        <div className='sidebar-box'>
                <div className='close-sidebar-box'>
                    <button type='button' onClick={showOrHideSidebar} className='close-btn'><img src={closeIcon} alt='cacher boîte de couches' className='close-icon'/></button>
                </div>
                <div className='sidebar-sub-box'>
                    <div className='sidebar-nav-box'>
                        <button className={activeSidebar==='layers'?'nav-btn nav-active-btn-layers layers-icon':'nav-btn layers-icon nav-btn-inactive'} type='button' onClick={() => chooseSidebar('layers')}>
                            <img src={layersIcon} alt='display or hide sidebar' className={activeSidebar==='layers'?'nav-img nav-active-img':'nav-img'}/>
                        </button>
                        <button className={activeSidebar==='mylayers'?'nav-btn nav-active-btn-mylayers mylayers-icon':'nav-btn mylayers-icon nav-btn-inactive'} type='button' onClick={() => chooseSidebar('mylayers')}>
                            <img src={mylayersIcon} alt='display or hide sidebar' className={activeSidebar==='mylayers'?'nav-img nav-active-img':'nav-img'}/>
                        </button>
                    </div>
                    {activeSidebar==='mylayers' && 
                    <div className='layers-box'>
                        <h1 className='sidebar-titles'>Mes couches</h1>
                        <DragDrop/>
                            <div className='mylayers-box'>
                            {myLayers.length>0?
                                        myLayers?.map((layer, layerindex) => {
                                            return (
                                                <Layer layer={layer} key={layerindex} myLayer/>
                                            )
                                    })
                                :
                                null
                            }
                        </div>
                    </div>}
                    {activeSidebar==='layers' && 
                    <div className='big-layers-box'>
                        <h1 className='sidebar-titles'>Couches</h1>
                        <div className='sidebar-all-layers-box'>
                            <div className='category-box'>
                            {layers.length>0 && layersCategories.length>0?
                                layersCategories.map((category) => {
                                    return (
                                        <div className='category-sub-box' key={category}>
                                            <div className='sidebar-layer-title-box'>
                                                <button type='button' onClick={() => showLayers(category)} className='display-layers-btn'><img src={displayCategory.includes(category)?minusIcon:plusIcon} alt='voir ou cacher couches' className='display-layers-icon'/></button>
                                                <h2 className='sidebar-sub-titles'>{category}</h2>
                                            </div>
                                            {
                                                layers.map((layer) => {
                                                    if (category === layer.layerCategory) {
                                                        return (
                                                            <div style={{display:displayCategory.includes(layer.layerCategory)?'flex':'none'}} className='layers-box' key={layer.id}>
                                                                <Layer layer={layer} myLayer={false}/>
                                                            </div>
                                                        )
                                                    }
                                                    return null
                                                })
                                            }
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                            </div>
                            <div style={{flex:1}}/>
                            <div className='base-map-box'>
                                <h1 className='sidebar-titles'>Carte de base</h1>
                                <div className='choose-map-box'>
                                    <button type='button' onClick={() => displayMap('mapbox://styles/mapbox/streets-v12')} aria-label="Choisir map 1" className='choose-map-btn'><img alt='' src={map1} className='choose-map-img'/></button>
                                    <button type='button' onClick={() => displayMap('mapbox://styles/mapbox/light-v11')} aria-label="Choisir map 2" className='choose-map-btn'><img alt='' src={map2} className='choose-map-img'/></button>
                                    <button type='button' onClick={() => displayMap('mapbox://styles/mapbox/dark-v11')} aria-label="Choisir map 3" className='choose-map-btn'><img alt='' src={map3} className='choose-map-img'/></button>
                                    <button type='button' onClick={() => displayMap('mapbox://styles/mapbox/satellite-streets-v12')} aria-label="Choisir map 4" className='choose-map-btn'><img alt='' src={map4} className='choose-map-img'/></button>
                                </div>
                            </div>
                            <PDFDownloadLink document={<PDF/>} fileName="landwiz.pdf" className='sidebar-pdf-link'>
                                Générer Rapport PDF
                            </PDFDownloadLink>
                            <img src={backgroundLogo} alt='' className='background-logo'/>
                        </div>
                    </div>}
                </div>
            <div style={{flex:1}}/>
            <img alt='koda logo' src={kodaLogo} className='koda-logo'/>
        </div>
    )
}

export default CarteLeftSidebar