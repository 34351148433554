/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initialState = {
    checkedLayers:[],
    myLayers:[],
    layers: [],
    layersCategories:[],
    displayMainSidebar:false,
    username:'',
    email:'',
    token:'faketoken',
    errorMessage:'',
    loadingMessage:'',
    isLoading:'',
    fakeProfile: {
        pic:'https://picsum.photos/200/200',
        name:'Edouard Blais',
        job:'Géologue',
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        employer:'Koda',
        office:'1250 Av. de la Station, Shawinigan, QC G9N 8K9',
        logo:'https://picsum.photos/200/200'
    },
    newOfficeCoords:[-72.7506747, 46.5450255]
}

const useLayerStore = create(
    persist((set) => ({
        ...initialState,
        reset: () => {set(initialState)},
      }),
      {
        name:'user info',
        partialize: (state) => ({username: state.username, email: state.email, token: state.token}),
        storage: createJSONStorage(() => sessionStorage),
      },
))

export default useLayerStore;