/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './utils/Header';
// import Connexion from './connexion/Connexion';
// import Inscription from './connexion/Inscription';
// import OubliMDP from './connexion/OubliMDP';
// import NouveauMDP from './connexion/NouveauMDP';
// import Pivot from './connexion/Pivot';
// import PrivateRoutes from './global/PrivateRoutes';
// import MesCartes from './cartes/MesCartes';
import Carte from './cartes/Carte';
// import MesProjets from './projets/MesProjets';
// import MonProjet from './projets/MonProjet';
// import NouveauProjet from './projets/NouveauProjet';
// import MesRapports from './rapports/MesRapports';
// import MonRapport from './rapports/MonRapport';
// import MesEntrevues from './entrevues/MesEntrevues';
// import MonEntrevue from './entrevues/MonEntrevue';
// import MesVisites from './visites/MesVisites';
// import MaVisite from './visites/MaVisite';
// import MesEtudes from './etudes/MesEtudes';
// import MonEtude from './etudes/MonEtude';
// import MonProfil from './profil/MonProfil';
import './App.css';

function App() {
  return (
    <BrowserRouter>
        <Header />
        <Routes>
          {/* <Route path="/connexion" element={<Connexion />} />
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/oublimdp" element={<OubliMDP />} />
          <Route path="/nouveaumdp" element={<NouveauMDP/>} />
          <Route element={<PrivateRoutes/>}>
            <Route path="/" element={<Pivot/>} /> */}
            {/* changer path pour /carte */}
            <Route path="/" element={<Carte />} />
            {/* <Route path="/projets" element={<MesProjets />} />
              <Route path="/projets/:id" element={<MonProjet />} />
              <Route path="/projets/nouveau" element={<NouveauProjet />} />
            <Route path="/rapports" element={<MesRapports />} />
              <Route path="/rapports/:id" element={<MonRapport />} />
            <Route path="/entrevues" element={<MesEntrevues />} />
              <Route path="/entrevues/:id" element={<MonEntrevue />} />
            <Route path="/visites" element={<MesVisites />} />
              <Route path="/visites/:id" element={<MaVisite />} />
            <Route path="/cartes" element={<MesCartes />} />
              <Route path="/cartes/:id" element={<Carte />} />
            <Route path="/etudes" element={<MesEtudes />} />
              <Route path="/etudess/:id" element={<MonEtude />} />
            <Route path="/profil" element={<MonProfil />} />
          </Route> */}
        </Routes>
    </BrowserRouter>
  );
}

export default App;
