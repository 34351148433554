import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import useLayerStore from './utils/useLayerStore';

const firebaseConfig = {
    apiKey: "AIzaSyCba0hRZmBOBeTUsOs4wYERkPrQop5PH1s",
    authDomain: "landwiz-config.firebaseapp.com",
    projectId: "landwiz-config",
    storageBucket: "landwiz-config.appspot.com",
    messagingSenderId: "334263834400",
    appId: "1:334263834400:web:38fbf9134933fa13c16945"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

async function getLayers() {
  const layersCol = collection(db, 'layers');
  const layersSnapshot = await getDocs(layersCol);
  const layersList = layersSnapshot.docs.map(doc => doc.data());
  const categoriesList = []
  if (layersList.length>0) {
    layersList.forEach((layer) => {
      if (!categoriesList.includes(layer.layerCategory)) {
        categoriesList.push(layer.layerCategory)
      }
    })
  }
  useLayerStore.setState({layers:layersList, layersCategories:categoriesList})
}

export default getLayers;
