/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import Switch from "react-switch";
import InputColor from 'react-input-color';
import useLayerStore from '../../utils/useLayerStore';
import eyeClosed from '../../images/eye-closed.svg';
import eyeFilled from '../../images/eye-filled.svg';
import deleteIcon from '../../images/delete-icon.svg';
import polygonIcon from '../../images/polygon-icon.svg';

function Layer({layer, myLayer}) {
    const [checked, setChecked] = useState(false)
    const [removingLayer, setRemovingLayer] = useState(false)

    const checkedLayers = useLayerStore((state) => state.checkedLayers)
    const myLayers = useLayerStore((state) => state.myLayers)

    const [newColor, setNewColor] = useState('');

    useEffect(() => {
        if (!myLayer) {
            if (checkedLayers.filter((lay) => lay?.id===layer?.id).length) {
                setChecked(true)
            }
        } else if (myLayer) {
            if (checkedLayers.filter((lay) => lay?.file?.name===layer?.file?.name).length) {
                setChecked(true)
            }
        }
    }, [])

    useEffect(() => {
        if (removingLayer) {
            const removeFromMyLayers = myLayers.filter((lay) => lay?.file?.name!==layer?.file?.name)
            useLayerStore.setState({myLayers: removeFromMyLayers})
            setRemovingLayer(false)
        }
    }, [removingLayer])

    useEffect(() => {
        if (newColor!== '') {
            const concernedLayer = myLayers.filter((lay) => lay?.file?.name===layer?.file?.name)
            concernedLayer[0].color.rgba = newColor.rgba;
            const myLayersWithoutMyLayer = myLayers.filter((lay) => lay?.file?.name!==layer?.file?.name)
            useLayerStore.setState({myLayers: [...myLayersWithoutMyLayer, concernedLayer[0]]})
        }
    }, [newColor])

    const handleCheckedLayers = () => {
        if (!checked && !myLayer) {
            const addToCheckedLayers = [...checkedLayers, layer];
            useLayerStore.setState({checkedLayers: addToCheckedLayers})
            setChecked(true)
        } else if (checked && !myLayer) {
            const removeFromCheckedLayers = checkedLayers.filter((lay) => lay?.id!==layer?.id)
            useLayerStore.setState({checkedLayers: removeFromCheckedLayers})
            setChecked(false)
        }else if (!checked && myLayer) {
            const addToCheckedLayers = [...checkedLayers, layer];
            useLayerStore.setState({checkedLayers: addToCheckedLayers})
            setChecked(true)
        } else if (checked && myLayer) {
            const removeFromCheckedLayers = checkedLayers.filter((lay) => lay?.file?.name!==layer?.file?.name)
            useLayerStore.setState({checkedLayers: removeFromCheckedLayers})
            setChecked(false)
        }
    }

    const deleteMyLayer = () => {
        const removeFromCheckedLayers = checkedLayers.filter((lay) => lay?.file?.name!==layer?.file?.name)
        useLayerStore.setState({checkedLayers: removeFromCheckedLayers})
        setRemovingLayer(true)
    }

    return (
        myLayer? 
            <div className='layers-sub-box'>
                <InputColor
                    initialValue={layer.color.hex}
                    onChange={setNewColor}
                    placement="left"
                    className="mylayer-color-indicator"
                />
                <h3 className='sidebar-layer-titles'>{layer.file.name}</h3>
                <div style={{flex:1}}/>
                <button onClick={handleCheckedLayers} type='button' className='see-mylayer-btn'><img src={checked? eyeFilled:eyeClosed} alt='voir ou cacher ma couche' className='see-mylayer-img'/></button>
                <button onClick={deleteMyLayer} type='button' className='see-mylayer-btn'><img src={deleteIcon} alt='supprimer ma couche' className='see-mylayer-img'/></button>
            </div>
            :
            <div className='flex-col'>
                <div className='layers-sub-box' style={{marginLeft:'1vw'}}>
                    <Switch
                        onChange={handleCheckedLayers}
                        checked={checked}
                        aria-labelledby="cliquez pour voir ou cacher la couche"
                        onColor='#0E0641'
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={16}
                        width={32}
                        handleDiameter={12}
                    />
                    <h3 className='sidebar-layer-titles'>{layer.name}</h3>
                </div>
                {checked && !layer?.paint?.stops &&
                <div className='layers-legend-box' style={{alignItems:'center'}}>
                    <div className={`layers-legend-symbol-${layer.type}`} style={{backgroundColor:layer.color}}/>
                    <h3 className='layers-legend-titles'>{layer.name}</h3>
                </div>
                }
                {checked && layer?.paint?.stops &&
                <div className='layers-legend-box'>
                    <img className='layers-legend-icon' src={polygonIcon} alt=''/>
                    <div className='layers-legend-sub-box'>
                        <h3 className='layers-legend-cat-titles'>{layer.name}</h3>
                        {layer.paint.stops.map(property => {
                            return (
                                <div className='layers-legend-property-box' key={property.attribute}>
                                    <div className={`layers-legend-symbol-${layer.type}`} style={{backgroundColor:property.color}}/>
                                    <h3 className='layers-legend-titles'>{property.attribute}</h3>
                                </div>
                            )
                        })}
                    </div>
                </div>
                }
            </div>
    )
}

export default Layer;