/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import randomColor from 'randomcolor';
import rgbHex from 'rgb-hex';
import handleDragDropFile from './handleDragDropFile';
import './DragDrop.css';
import useLayerStore from "../../utils/useLayerStore";
import infoIcon from '../../images/info-icon.svg';
import fileExample from '../../images/file-example.PNG';
import closeIcon from '../../images/close-icon.svg';

function DragDrop() {
    const [dragActive, setDragActive] = useState(false);
    const [parsedFile, setParsedFile] = useState('');
    const [displayInfo, setDisplayInfo] = useState(false)
    const inputRef = useRef(null);

    const myLayers = useLayerStore((state) => state.myLayers)

    const layerRGBAColor = randomColor( {format: 'rgba', alpha:0.5});
    const colorLengthConsidered = (layerRGBAColor.length)-4
    const layerHEXcolor = `#${rgbHex(`${layerRGBAColor.slice(0, colorLengthConsidered)}50%)`)}`
    
    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
    
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleDragDropFile(e.dataTransfer.files[0], setParsedFile);
      }
    };
    
    const handleChange = (e) => {
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        handleDragDropFile(e.target.files[0], setParsedFile);
        e.target.value = null;
      }
    };

    useEffect(() => {
        if (parsedFile!=='') {
            const addToMyLayers = [...myLayers, {file:parsedFile, color: {rgba:layerRGBAColor, hex:layerHEXcolor}}];
            useLayerStore.setState({myLayers: addToMyLayers})
        }
    }, [parsedFile])
    
    const onButtonClick = () => {
      inputRef.current.click();
    };

    const showInfo = () => {
      if (displayInfo) {
        setDisplayInfo(false)
      } else if (!displayInfo) {
        setDisplayInfo(true)
      }
    }
    
    return (
      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input ref={inputRef} type="file" id="input-file-upload" multiple onChange={handleChange} accept=".geojson, .json"/>
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
          <div className='upload-text-box'>
            <div className='upload-text-sub-box'>
              <p className='upload-text'>Déposez un fichier GeoJSON</p>
              <button type='button' className='info-btn' onClick={showInfo} aria-label='montrer modele de fichier pour ajouter une couche'><img alt='' src={infoIcon} className='info-icon'/></button>
            </div>
            <button className="upload-button" onClick={onButtonClick} type='button'>...ou téléchargez-le</button>
          </div> 
        </label>
        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}/> }
        {displayInfo && 
          <div className='info-popup'>
            <div className='close-infobox-box'>
              <button type='button' onClick={showInfo} className='info-btn'><img src={closeIcon} alt='cacher info' className='info-icon'/></button>
            </div>
            <p className='upload-text'>Votre fichier GEOJSON devrait avoir une structure similaire à la suivante:</p>
            <img src={fileExample} alt='exemple de fichier' className='file-example-img'/>
          </div>
        }
      </form>
    );
};

export default DragDrop;