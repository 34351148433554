/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React from 'react'
import closeIcon from '../../images/close-icon.svg';

function CarteInfobar({showOrHideInfobar, infobarData, layerAttributes}) {
    return (
        <div className='infobar-box'>
            <div className='close-sidebar-box'>
                <button type='button' onClick={showOrHideInfobar} className='close-btn'><img src={closeIcon} alt='cacher boîte de couches' className='close-icon'/></button>
            </div>
            <div className='infobar-sub-box'>
                <h2 className='sidebar-titles'>Information sur la couche</h2>
                <div className='layer-info-box'>
                    {infobarData?
                        Object.entries(infobarData).map(([key, value]) => {
                            let attribute = ''
                            layerAttributes.forEach((att) => {
                                if (att.key===key) attribute=att.value
                            })
                            if (attribute && value) {
                                return (
                                    <div className='layer-info-sub-box' key={key}>
                                        <p className='infobar-attribute'>{attribute}</p>
                                        <p className='infobar-text'>{value}</p>
                                    </div>
                                    )
                            }
                            return null
                        })
                        :
                        <h3 className='sidebar-sub-titles' style={{color:'#F64C72'}}>Aucune information disponible</h3>
                    }
                </div>
            </div>
        </div>
    )
}

export default CarteInfobar;