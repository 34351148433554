const customLayerLoader = (map, layer, isMyLayer) => {
  if (!isMyLayer) {

    if (map.getLayer(layer.sourceName)) {
      map.removeLayer(layer.sourceName)
    }

    if (map.getSource(layer.sourceName)) {
      map.removeSource(layer.sourceName)
    }

    if (layer.service==='mvt') {
      map.addSource(layer.sourceName, {
        type: 'vector',
        tiles: [layer.url],
        minzoom: 5,
        maxzoom: 14
      })

      if (layer.type==='circle') {
        map.addLayer({
          id: layer.sourceName,
          source: layer.sourceName,
          'source-layer': layer.sourceLayer,
          minzoom: 5,
          maxzoom: 22,
          type: layer.type,
          paint: {'circle-color':layer.paint.circleColor, 'circle-radius': layer.paint.circleRadius}
        })
      } else if (layer.type==='line') {
        map.addLayer({
          id: layer.sourceName,
          source: layer.sourceName,
          'source-layer': layer.sourceLayer,
          minzoom: 5,
          maxzoom: 22,
          type: layer.type,
          paint: {'line-color':layer.paint.lineColor, 'line-width': layer.paint.lineWidth}
        })
      } else if (layer.type==='fill') {
        if (layer.paint.fillColor) {
          map.addLayer({
            id: layer.sourceName,
            source: layer.sourceName,
            'source-layer': layer.sourceLayer,
            minzoom: 5,
            maxzoom: 22,
            type: layer.type,
            paint: {'fill-color':layer.paint.fillColor}
          })
        } else if (layer?.paint?.stops) {
          const categories = []
          layer?.paint?.stops.forEach((stop) => {
            const category = []
            category.push(stop.attribute)
            category.push(stop.color)
            categories.push(category)
          })
          map.addLayer({
            id: layer.sourceName,
            source: layer.sourceName,
            'source-layer': layer.sourceLayer,
            minzoom: 5,
            maxzoom: 22,
            type: layer.type,
            paint: {'fill-color':{'property':layer.paint.property, 'type':layer.paint.type, 'stops':categories,'default': layer.paint.default}},
            paintCategories: categories
          })
        }
      } 
    } else if (layer.service==='wms') {
      map.addSource(layer.sourceName, {
        type: 'raster',
        tiles:layer.tiles,
        tileSize:layer.tileSize
      })
      map.addLayer(
        {
        'id': layer.id,
        'type': 'raster',
        'source': layer.sourceName,
        'paint': {},
        },
      );
    }
  } else if (isMyLayer) {
    if (map.getLayer(layer.file.name)) {
      map.removeLayer(layer.file.name)
    }
    if (map.getSource(layer.file.name)) {
      map.removeSource(layer.file.name)
    }
    map.addSource(layer.file.name, {
      type: 'geojson',
      data: layer.file,
    })
    let layerType='';
    if (layer.file.features[0].geometry.type === 'MultiPolygon' || layer.file.features[0].geometry.type === 'Polygon') {
      layerType='fill'
    } else if (layer.file.features[0].geometry.type === 'LineString' || layer.file.features[0].geometry.type === 'MultiLineString') {
      layerType='line'
    } else if (layer.file.features[0].geometry.type === 'Point' || layer.file.features[0].geometry.type === 'MultiPoint') {
      layerType='circle'
    }
    if (layerType==='fill') {
      map.addLayer({
        id: layer.file.name,
        source: layer.file.name,
        type: layerType,
        'paint': {
          'fill-color': layer.color.rgba,
        },
        data:layer.file
      })
    } else if (layerType==='line') {
      map.addLayer({
        id: layer.file.name,
        source: layer.file.name,
        type: layerType,
        'paint': {
          'line-color': layer.color.rgba,
          'line-width':2,
        },
        data:layer.file
      })
    } else if (layerType==='circle') {
      map.addLayer({
        id: layer.file.name,
        source: layer.file.name,
        type: layerType,
        'paint': {
          'circle-color': layer.color.rgba,
          'circle-radius':4,
        },
        data:layer.file
      })
    }
  }
}

export default customLayerLoader;