/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    gap:'20px',
    alignItems:'center'
  },
  section: {
    margin: 10,
    padding: 10,
  },
});

function PDF() {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Rapport PDF Landwiz</Text>
        </View>
        <View style={styles.section}>
          <Text>Fonctionnalité en cours de développement</Text>
        </View>
      </Page>
    </Document>
  )
};

export default PDF;